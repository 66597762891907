<template>
  <div class="bank">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Thẻ ngân hàng Của tôi"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Thẻ ngân hàng Của tôi</span
        >
      </template>
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
    </van-nav-bar>

    <van-form @submit="onSubmit">
      <!-- language-ch 请输入持卡人真实姓名 -->
      <van-field
        center
        v-model="formList.name"
        name="name"
        label="Tên chủ tài khoản:"
        :rules="[{ required: true, message: '' }]"
        placeholder="Vui lòng nhập tên thật của bạn."
      />

      <!-- <van-field
        center
        v-model="formList.ifsc_code"
        name="ifsc_code"
        label="รหัส IFSC"
        :rules="[{ required: true, message: 'Please fill in name ' }]"
        placeholder="รหัส IFSC"
      /> -->
      <!-- language-ch 请输入银行名称 -->
      <van-field
        center
        v-model="formList.bank"
        name="bank"
        label="Tên ngân hàng:"
        :rules="[{ required: true, message: ' ' }]"
        placeholder="Vui lòng điền tên ngân hàng của bạn"
      />
      <!-- language-ch 请输入银行卡号 -->
      <van-field
        center
        v-model="formList.card"
        name="card"
        label="Số tài khoản:"
        :rules="[{ required: true, message: ' ' }]"
        placeholder="Vui lòng nhập số tài khoản của bạn"
      />
      <!-- language-ch 请输入电话号码 -->
      <!-- <van-field
        center
        v-model="formList.mobile"
        name="mobile"
        label="số điện thoại"
        :rules="[{ required: true, message: 'Please enter your Mobile' }]"
        placeholder="số điện thoại"
      /> -->
      <!-- language-ch 请输入email -->
      <!-- <van-field
        center
        v-model="formList.email"
        name="email"
        label="Ghi chú"
        :rules="[{ required: true, message: 'Please enter the Email' }]"
        placeholder="Ghi chú"
      /> -->
      <div class="list-red">
        Lưu ý: Thông tin thẻ ngân hàng là thông tin quan trọng, không sửa đổi
        sau khi thêm! Xin cám ơn ạ.
      </div>
      <div style="margin: 16px">
        <van-button
          block
          type="danger"
          native-type="submit"
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
          >Gửi Thông Tin</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { userBank, addBank } from '@/api/use.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      formList: {
        dataid: 0,
        name: '',
        ifsc_code: '',
        bank: '',
        card: '',
        mobile: '',
        email: '',
      },
    }
  },
  mounted() {},
  created() {
    this.getUserBank()
  },
  methods: {
    async onSubmit() {
      const { data } = await addBank(this.formList)
      console.log(data)
      data.ret === 1
        ? this.$toast('Added successfully') &&
          this.$router.push({
            path: '/withdraw',
            query: { isshowpassword: true },
          })
        : this.$toast('Thẻ ngân hàng đã bị ràng buộc')
      data.msg =
        'Bank card number already exists！' &&
        this.$router.push({
          path: '/withdraw',
          query: { isshowpassword: true },
        })
    },

    async getUserBank() {
      const {
        data: { data, ret },
      } = await userBank()
      if (data.length > 0 && ret === 1) {
        this.formList.dataid = data[0].id
        this.formList.name = data[0].name
        this.formList.bank = data[0].bank
        this.formList.card = data[0].card
        this.formList.open = data[0].open
        this.formList.mobile = data[0].mobile
        this.formList.upi = data[0].upi
        this.formList.email = data[0].email
        this.formList.ifsc_code = data[0].ifsc_code
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bank {
  font-family: 'PingFang-Regular';
  .van-form {
    margin: 15px 0;
    color: #000;
    .van-field {
      /deep/ .van-field__label {
        width: 255px !important;
      }
      /deep/ .van-field__body {
        input {
          text-align: center;
        }
      }
    }
    .list-red {
      margin-top: 62px;
      color: #dc1323;
      font-size: 28px;
      text-align: center;
    }
  }
}
</style>
